// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

//---- jQuery(UI) ----
import './src/jquery'
import 'jquery-ui/dist/jquery-ui'

//---- JavintoAppPages
require("./packs/content/content_assets")
require("./packs/content/content_pages")

//---- FancyBox, see application.scss for CSS
import { Fancybox } from "@fancyapps/ui/dist/index.esm.js";
window.addEventListener("turbo:load", () => {
  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });  
});


require("./packs/bootstrap_activation");
require("./packs/webarchives");
require("./packs/main");