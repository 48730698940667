window.addEventListener("turbo:load", (event) => {
  bind_archive_dates_helpers(document);
})

function bind_archive_dates_helpers() {
  const more_link= document.querySelector('#archive_dates_more');
  const less_link= document.querySelector('#archive_dates_long');

  if (more_link) {
    // Show all/more archives dates
    more_link.addEventListener('click', (event) => {
      event.target.classList.add('d-none');
      less_link.classList.remove('d-none')
    })
  }

  if (less_link) {
    // Show less archives dates
    less_link.addEventListener('click', (event) => {
      event.target.classList.add('d-none');
      more_link.classList.remove('d-none');
    })
  }
}